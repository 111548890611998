<template>
  <div>
    <span class="mr-3 text-indigo-900">Marques</span>
    <div class="w-44 lg:w-80">
      <multiselect
        placeholder=""
        select-label=""
        deselect-label=""
        selected-label=""
        track-by="value"
        label="label"
        :options="options"
        :value="value"
        :multiple="multiple"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "MarqueFilter",
  props: {
    value: {
      type: [Object, Array],
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [
        { label: "Europe Energie", value: "europe_energie" },
        { label: "Technitoit", value: "technitoit" },
      ],
    };
  },
  methods: {
    handleInput(value) {
      this.$emit("input", { field: this.fields[0], value });
    },
  },
};
</script>
